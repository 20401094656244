<template>
    <v-dialog
      :content-class="$isMobile() ? 'dialog' : 'uploadDocDialog'"
      scrollable
      transition
      width="450"
      v-model="dialogValue"
      :fullscreen="$isMobile() ? true : false"
    >
      <v-card color="var(--white)">
        <div class="tmb-style_title-header tmb-style_bg-dark">
          <v-toolbar-title class="tmb-style_title-header__content tmb-style_white">
            {{ notification.type }}
          </v-toolbar-title>
          <v-btn
            icon
            @click="close"
            class="rounded-lg tmb-style_title-header__close tmb-style_bg-main"
            height="36"
            width="36"
          >
            <v-icon color="white">close</v-icon>
          </v-btn>
        </div>
        <v-card-text class="py-4" style="background-color: var(--white)">
              <b class="tmb-style_black">{{ notification.message }}</b>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "SelectedNotification",
    props: {
      value: Boolean,
      notification: Object
    },
    computed: {
      dialogValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    },
    methods: {
      close() {
        this.dialogValue = false; // Закрытие диалога
      }
    }
  };
  </script>
  