<template>
  <v-container fluid>
    <!-- Title -->
      
    
    <v-row class="title__link-v flex-column">
      <v-col class="d-flex align-center justify-center">
        <v-icon color="white" style="margin-right: 3px;">
          history
        </v-icon>
        <span style="color: var(--white);">{{ $i18n.t('paymentsHistory') }}</span>

      </v-col>

      <v-col class="pa-0 divider">
        <v-divider dark></v-divider>
      </v-col>
    </v-row>
 
    <!-- Title list -->
    <v-row class="row-title-item mb-2 mt-0">
      <v-col class="col__item title-item">
        {{ $i18n.t('dateTime') }}
      </v-col>
      <v-col class="col__item title-item">
        {{ $i18n.t('type') }}
      </v-col>
      <v-col class="col__item title-item">
        {{ $i18n.t('message') }}
      </v-col>
      <v-col class="col__item title-item">
        {{ $i18n.t('actions') }}
      </v-col>
    </v-row>
    <!-- List -->
    <template v-if="notifications.length > 0 && !loadingFlag">
    <!-- pc-table -->
      <v-row class="row-list-item" v-for="notif in notifications" @click="selectNotification(notif)" :key="notif.id">
          <v-col class="col__item list-item" >
            {{ getLocalDateTime(notif.created_at) }}
          </v-col>
          <v-col class="col__item list-item"  >
            <!-- :style="{'color': statusStyleColor(notif)}" -->
            <v-chip small label
            :color="statusLabelColor(notif.type)"
            :style="`color: ${statusStyleColor(notif.type)}`"
            class="px-1"
            >
              {{ getStatusText(notif.type) }}
            </v-chip>
          </v-col>
          <!-- small-item -->
          <v-col class="col__item list-item">
            <div class="message-notif">{{ notif.message }}</div>
          </v-col>
          <v-col class="col__item list-item gap-2">
            <!-- <v-btn
              fab
              dark
              color="var(--red)"
              style="height: 25px;width: 25px;"
            >
              <v-icon dark small>
                mdi-eye
              </v-icon>
            </v-btn> -->
            <v-btn
            :loading="notif.loadingItem"
              fab
              dark
              color="var(--green-success)"
              style="height: 25px;width: 25px;"
            >
              <v-icon dark small>
                mdi-eye
              </v-icon>
            </v-btn>
            <v-btn
            @click.stop="deleteNotification(notif)"
            :loading="notif.loadingItem"
              fab
              dark
              color="var(--red)"
              style="height: 25px;width: 25px;"
            >
              <v-icon dark small>
                mdi-delete
              </v-icon>
            </v-btn>
            
          </v-col>
    </v-row>
    </template>
    <Preloader class="d-flex justify-center" v-if="loadingFlag"></Preloader>
    <!-- Paggination -->
    <!-- <v-row v-if="notifications.length > 0">
      <v-col>
        <v-pagination v-if="notifications.length > 0" v-model="$store.state.currentPage"
          :length="Math.ceil(notifications.length / $store.state.itemsPerPage)"
          color="var(--red)"></v-pagination>
      </v-col>
    </v-row> -->
    
    <v-row class="justify-center">
    <!-- Title if payments = 0 -->
    <v-col class="d-flex align-center flex-column justify-center" v-if="notifications.length == 0 && !loadingFlag">
      <span class="mb-4 font-weight-bold tmb-style_fz24 tmb-style_white">
        {{ $t('historyEmpty') }}
      </span>
    </v-col>
    
  </v-row>

    <SelectedNotification 
    v-model="isVisible"
    :notification='selectedNotification'
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SelectedNotification from "./SelectedNotification.vue";
import Preloader from "@/components/Preloader";
export default {
  name: "Notifications",
  components: { 
    SelectedNotification,
    Preloader },
  data() {
    return {
      loadingFlag: false,
      selectedNotification: {},
      isVisible: false,
    }
  },
  async mounted() {
    //getUserPaymentsHistory
    this.loadingFlag = true;
    if (this.notifications.length == 0) {
      await this.$store.dispatch('notifications/awaitGetNotifications');
    }
    this.loadingFlag = false;
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications/getNotifications',
      user: 'getUserInfo',
    }),
    paginatedPayments() {
      const start = (this.$store.state.currentPage - 1) * this.$store.state.itemsPerPage;
      const end = start + this.$store.state.itemsPerPage;
      return this.$store.getters.getUserPayments.slice(start, end);
    }
  },
  methods: {
    selectNotification(notification) {
      this.isVisible = true;
      this.selectedNotification = notification;
    },
    async deleteNotification(item) {
      item.loadingItem = true;
      try {
        await this.$store.dispatch('notifications/awaitDeleteNotification', item.id);
      } catch (error) {
        console.log(error);
      } finally {
        item.loadingItem = false;
      }
    },
    getLocalDateTime(serverDate){
      const serverDateTime = new Date(serverDate);
      const userTimezoneOffset = new Date().getTimezoneOffset(); 
      const localDateTime = new Date(serverDateTime.setMinutes(serverDateTime.getMinutes() - userTimezoneOffset));
      return localDateTime.toLocaleString();
    },
    getStatusText(type) {
      switch (type) {
        case 'BONUS':
          return this.$t('bonus');
        case 'LEVEL':
          return this.$t('justLevel');
          case 'MESSAGE': 
            return this.$t('message');
            case 'BAN': 
              return this.$t('ban');
              case 'COMPENSATION':
                return this.$t('compensation');
      }
    },
    getTypeClass(type) {
      switch (type) {
        case 'BONUS':
          return '_green';
        case 'LEVEL':
          return '_gray';
          case 'MESSAGE': 
            return '_main';
            case 'BAN': 
              return '_red';
              case 'COMPENSATION':
                return '_yellow';
      }
    },
    statusLabelColor(status) {
      switch (status) {
        case 'COMPENSATION':
          return 'var(--yellow)';
        case 'MESSAGE':
          return 'var(--dark-d-flamingo)';
        case 'BONUS':
          return 'var(--green-success)';
        case 'LEVEL':
          return 'var(--yellow)';
        case 'BAN':
          return  'var(--red)';
          default: 
          return status
      }
    },
    statusStyleColor(status) {
      switch (status) {
        case 'COMPENSATION':
          return 'var(--black)';
        case 'MESSAGE':
          return 'var(--white)';
        case 'BONUS':
          return 'var(--white)';
        case 'LEVEL':
        return 'var(--black)';
        case 'BAN':
          return  'var(--white)';
          default: 
          return status
      }
    },
    getIsViewedClass(is_viewed) {
      switch (is_viewed) {
        case true:
          return '_green';
        case false:
          return '_gray';
      }
    },
    getIsViewed(is_viewed) {
      switch (is_viewed) {
        case true:
          return this.$t('yes');
        case false:
          return this.$t('no');
      }
    },
    getType(type) {
      
    },
  }
}
</script>

<style lang="scss" scoped>
.divider{
  @media (min-width:650.98px){
      
  }
  margin-bottom: 24px!important;
}
.message-notif{
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width:500px){
    max-width: 70px;
  }
}
.items-grid{
  padding: 20px 10px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 12px;
}
.pc-table{
  @media (max-width:650px){
      display: none;
  }
}
.mobile-table{
  @media (min-width:650.98px){
    display: none;
}
}
.row-title-item {
  color: var(--white);
//  @media (max-width:650px){
//    display: none;
//}
}

.title-item {
  line-height: 1.5;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width:500px){
      font-size: 12px;
  }
//  @media (max-width:650.98px){
//    justify-content: flex-start;
//}
}

.col__item {
  
padding: 0;
}
.row-list-item {
  &:nth-child(odd) {
    background-color: var(--main-flamingo);

  }
  border: 1px solid transparent;
  cursor: pointer;
  color: var(--white);
  transition: border 0.3s;
  @media (any-hover: hover){
    &:hover{
      border: 1px solid var(--red);
    }
  }
}
.isWithDraw{
  color: #ff6565!important;
  //position: relative;
  //&::before{
  //  content: '';
  //  position: absolute;
  //  z-index: 1;
  //  background-color: rgba(0, 0, 0, 0.15);
  //  width: 100%;
  //  height: 100%;
  //  top: 0;
  //  left: 0;
  //  
  //}
}
.list-item {
  
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  @media (max-width:500px){
    font-size: 10px;
}
//  @media (max-width:650.98px){
//    justify-content: flex-start;
//}
min-height: 40px; 
  @media (min-width:650.98px){
  }
  padding: 0;
}
.small-item{
  @media (max-width:650px){
    flex: 0 1 55px;  
  }
  
}
.row_style {
  color: var(--white);

}

.success_status {
  color: var(--green-success);
}

.rejected_status {
  color: var(--red);
}

.warning_status {
  color: #FFC107;
}
</style>